import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import TruckIcon from "./truck-icon.svg"
import ReactTooltip from 'react-tooltip';
import { ApiUrl } from "../helpers/ApiUrl";


function Results(props) {

    function hideResults() {
        if (window.matchMedia('(max-width: 767px)').matches) {
          document.getElementById("results").style.display = "none";
          document.getElementById("calculator").style.display = "block";
        }
    }
    
    if (props.prices[0] == "No prices") {
        return (
            <div className='prices-container row'>
                <a className="back-button" id="back-button" onClick={hideResults}><i className="bi bi-chevron-left"></i>Back to calculator</a>
                <div className="no-price-card text-center pt-5 pb-5">
                    <div>
                        <img src={TruckIcon} className="truck-icon" />
                        <h4 className="no-results">No prices found!</h4>
                    </div>
                </div>
            </div>
        )
    } else if (props.prices[0] == "Loading") {
        return (
            <div className="spinner-border loading-spinner" role="status">
                <span className="sr-only"></span>
            </div>
        )
    } else if (!props.prices[0]) {
        return (
            <div className='prices-container row'>
                <a className="back-button" id="back-button" onClick={hideResults}><i className="bi bi-chevron-left"></i>Back to calculator</a>
                <div className="no-price-card text-center pt-5 pb-5">
                <div>
                    <img src={TruckIcon} className="truck-icon" />
                    <h4 className="no-results">Fill the form on the left and click "calculate"</h4>
                </div>
                </div>
            </div>
        )
    } else {
        let cards = props.prices.sort(comparePrices).map( (priceData, index) =>
            (
            <div className="price-card" key={index} onDoubleClick={redirectToShipmentBooking}>
                <div className='text-center'>
                    <h6 className="pb-3 service-name">{priceData.service}</h6>
                    <h6 className="price-card-muted">TOTAL PRICE</h6>
                    <h4 className="total-price">{roundToTwo(priceData.total)}{convertCurrencyToSymbol(priceData.currency)}
                        {priceData.priceType == 'online' ? <span className='text-muted fw-light opacity-75' data-place='right' data-tip='Public price'>*</span> : ''}
                    </h4>
                </div>
                <div className='d-flex justify-content-center'>
                        <text className='estimated-dates'>
                            { priceData.estimatedCollection && priceData.estimatedDelivery ? 
                                <div>{formatDate(priceData.estimatedCollection)} / {formatDate(priceData.estimatedDelivery)}</div>
                            : <div></div> }
                        </text>
                </div>
                <div className='pt-3'>
                    <div className='d-flex justify-content-between pt-2'>
                        <text className='price-card-muted'>TRANSPORT PRICE</text>
                        <b className='price-value'>{roundToTwo(priceData.price)}{convertCurrencyToSymbol(priceData.currency)}</b>
                    </div>
                    <div className='d-flex justify-content-between pt-2'>
                        <text className='price-card-muted'>SURCHARGES (BAF incl)</text>
                        <b className='price-value' data-place="bottom" data-html={true} data-tip={priceData.surchargesBreakdown?.map((surchargeItem) => 
                                `${surchargeItem['name']}: ${roundToTwo(surchargeItem['amount'])}${convertCurrencyToSymbol(priceData.currency)}`
                            ).join("<br/>")}>
                            {roundToTwo((priceData.total - priceData.price) || 0)}{convertCurrencyToSymbol(priceData.currency)}
                        </b>
                        <ReactTooltip effect="solid" />
                    </div>
                </div>
            </div>
            )
        );

        return (
            <Fragment>
            <div className='prices-container row'>
                <a className="back-button" id="back-button" onClick={hideResults}><i className="bi bi-chevron-left"></i>Back to calculator</a>
                {cards}
            </div>
            </Fragment>
        )
    }
}

  const redirectToShipmentBooking = () => {
    window.location = ApiUrl() + "/queries/new?origin=cargopricelist"
  }

  function roundToTwo(num) {
    var result = (Math.round(num + "e+2") + "e-2")
    result = isNaN(result) ? 0 : parseFloat(result).toFixed(2);
    if (Math.abs(result) < 0.05) result = 0;

    return result;
  }

  function formatDate(date) {
    return date.slice(0, 10).split("-").reverse().join(".");
  }

  function convertCurrencyToSymbol(currency) {
    if (currency == "EUR") {
        return "€"
    } else {
        return " " + currency
    }
  }

  function comparePrices(a, b) {
    return (parseFloat(a.total) < parseFloat(b.total)) ? -1 : 1
  }

export default Results