export const DimensionSettings = {
    "length": {
        "EUR": 120,
        "FIN": 120,
        "HPL": 80,
        "QPL": 60,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "width": {
        "EUR": 80,
        "FIN": 100,
        "HPL": 60,
        "QPL": 40,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "height": {
        "EUR": "",
        "FIN": "",
        "HPL": "",
        "QPL": "",
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    }
}

export const ImperialDimensionSettings = {
    "length": {
        "EUR": 48,
        "FIN": 48,
        "HPL": 32,
        "QPL": 24,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "width": {
        "EUR": 32,
        "FIN": 40,
        "HPL": 24,
        "QPL": 16,
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    },
    "height": {
        "EUR": "",
        "FIN": "",
        "HPL": "",
        "QPL": "",
        "CTN": 0,
        "PLL": 0,
        "LOAD": ""
    }
}

export const PackageOptions = {
    "EUR": "EUR pallet (EUR)",
    "FIN": "FIN pallet (FIN)",
    "HPL": "Half pallet (HPL)",
    "QPL": "Quarter pallet (QPL)",
    "CTN": "Package (CTN)",
    "PLL": "Pallet (PLL)",
    "LOAD": "Full load"
}

export const PalletData = {
    type: "EUR",
    quantity: 1,
    length: 120,
    width: 80,
    height: "",
    weight: "",
    cbm: 0,
    ldm: 0.4,
  };

export const ImperialPalletData = {
    type: "EUR",
    quantity: 1,
    length: 48,
    width: 32,
    height: "",
    weight: "",
    cbm: 0,
    ldm: 0.4,
  };